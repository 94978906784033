import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import Layout from '../../layouts';
import SEO from '../../components/seo/SEO';
import { pageTitle, phoneToTel } from '../../utils/helpers';
import { useSiteMetadata } from "../../hooks/use-site-metadata"

export default function Contact(props) {
  useEffect(() => {
    $('#contact-form').submit(function(e) {
      e.preventDefault();

      var $form = $(this);
      $.post($form.attr('action'), $form.serialize()).then(function() {
        $form.trigger('reset');
        $('#form-message').html(
          'Your message was sent successfully. Thank you!'
        );
      });
    });
  });
  
  const currentPage = props.data.wordpressPage;
  const site = useSiteMetadata();

  const currentPageTitle = currentPage.yoast_meta.yoast_wpseo_title
    ? currentPage.yoast_meta.yoast_wpseo_title
    : pageTitle({
        site: site.title,
        page: currentPage
      });
  const acfMetaData = props.data.wordpressAcfOptions.options;

  return (
    <Layout>
      <Helmet bodyAttributes={{ id: `page-${currentPage.slug}` }}>
        <title>{currentPageTitle}</title>
      </Helmet>
      <SEO node={currentPage} site={site} />
      <section className='section-info'>
        <div className='content'>
          <div className='content--line' />
          <div className='content--main'>
            <p className='eyebrow'>
              <span>Contact Us</span>
            </p>
            <span dangerouslySetInnerHTML={{ __html: currentPage.content }} />
            <p>
              <b>New business &amp; general inquiries:</b><br/> 
              <a href={`mailto:${acfMetaData.contact_email}`}>
                {acfMetaData.contact_email}
              </a>
              <span className='hide-mobile'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <span
                className='hide-mobile'
                dangerouslySetInnerHTML={{
                  __html: acfMetaData.contact_phone
                }}
              />
              <br className='show-mobile' />
              <a
                href={`tel:${phoneToTel(acfMetaData.contact_phone)}`}
                className='show-mobile'
              >
                {acfMetaData.contact_phone}
              </a>
            </p>
            <p><b>Press Inquiries:</b> <a href="mailto:pr@firewoodmarketing.com">pr@firewoodmarketing.com</a></p>

            {/* Form // */}

            {/* <form id="contact-form" name="contact" method="post" data-netlify="true" data-netlify-recaptcha="true" data-netlify-honeypot="bot-field"> */}
            <form
              id='contact-form'
              name='contact'
              method='post'
              data-netlify='true'
              data-netlify-honeypot='bot-field'
            >
              <div className='form-fields'>
                <div className='form-field'>
                  <label htmlFor='your-name'>
                    Your name<span className='red'>*</span>
                    <br />
                  </label>
                  <input
                    type='text'
                    name='your-name'
                    id='your-name'
                    size='40'
                    autoComplete='name'
                    required
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='your-email'>
                    Your email<span className='red'>*</span>
                    <br />
                  </label>
                  <input
                    type='email'
                    name='your-email'
                    id='your-email'
                    size='40'
                    autoComplete='email'
                    required
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='your-message'>Your message</label>
                  <br />
                  <textarea name='your-message' cols='40' rows='10' />
                  <span className='red'>* Required</span>
                </div>
              </div>
              <div id='form-message' />
              <button id='submit' type='submit'>
                Send
              </button>
              {/* Hidden */}
              <div data-netlify-recaptcha='true' />
              <input type='hidden' name='form-name' value='contact' />
              <input type='hidden' name='bot-field' />
              {/* <input type="hidden" name="g-recaptcha-response" /> */}
            </form>
            {/* // Form */}
          </div>
        </div>
        <div className='image'>
          <Img
            fluid={currentPage.featured_media.localFile.childCloudinaryAsset.fluid}
          />
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "Contact" }) {
      title
      slug
      content
      featured_media {
        localFile {
          childCloudinaryAsset {
            fluid(maxWidth: 1000) {
              ...CloudinaryAssetFluid
            }
          }
        }
      }
      ...SEO
    }
    wordpressAcfOptions {
      ...acfMetaDataQuery
    }
  }
`;
